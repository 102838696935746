<template>
  <b-card
    v-if="data"
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>我的事项</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0">

      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>

        <b-col
          xl="3"
          sm="6"
          class="mb-2 mb-xl-0"
        >
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-primary"
              >
                <feather-icon size="24" icon="PenToolIcon"/>
              </b-avatar>
            </b-media-aside>

            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ pendingLiableApprovalsCount }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                个待审批
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    pendingLiableApprovalsCount: {
      type: Number,
      default: 0,
    },
  },
}
</script>
